body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  font: normal 100% Merriweather, Georgia, serif;
  color: #1a1a1a;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;

  width : 100%; /* 1200px pour 1262,8px (1280) */
  max-width: 1350px;

  background: #ffffff;
}

.banniere {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;

  	width: 100%;
  	height:250px;

  	background-image: url(./images/banniere_moyenne.JPG);
  	background-repeat: no-repeat;
  	background-position: bottom center;
  }

  .intro {
    display : flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #ffffff88;
    padding: 0.5rem;

    font-weight: bold;
    font-size: 2rem;
    color: #1A4680;
  }

    .sous-intro {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }

   .entete {
    display : flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-align: center;
    background: #ffffff88;
    padding: 0.5rem;

    font-weight: bold;
    font-size: 1.75rem;
    color: #1A4680;
  }

  .entete a{
    color: #1A4680;
  }

  .cacher {
    display:none;
  }


.article {
  display : flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;

  width: 40rem;
  margin-top: 1rem;

  border: 2px solid #1A4680;;
  border-radius: 5px;
}

.titre {
  display : flex;
  flex-direction: row;
  justify-content: center;

  font-weight: bold;
  font-size:1.5rem;
  color:#111180;
}

.poeme {
  display : flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;

  width:90%;
  margin-left: 5rem;
  margin-right: 5rem;

  font-size:1.25rem;
  font-style: italic;
  text-align: center;
  color: #1A4680;;
}

.modif_ligne{
  color: blue;
  margin-left:1px;
}

.ligne {
  display : flex;
  flex-direction: row;
  justify-content: center;

  margin : 0.5rem;
}

.ligne-fin {
  display : flex;
  flex-direction: row;
  justify-content: center;
}

.theme  {
  width:90%;
  text-align: right;

  font-style: italic;
  font-size:0.5rem;
  color: #555555;
}

.groupe_bouton{
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 40rem;

  margin-top: 1rem;
}

.bouton {
  display : flex;
  justify-content: center;
  flex-basis: 100%;

  margin: 0.25rem;
  padding: 0.5rem;

  background: #ffffff;
  border: 2px solid #1A4680;
  border-radius: 5px;

  font-size:1rem;
  text-align: center;
  color: #1A4680;
}

.bouton.vert{
  color: #4AAA16;
  border-color: #4AAA16;
}

.bouton.fond_vert{
  background: #4AAA16;
  border-color: #4AAA16;
  color: #ffffff;
}

.bouton.fond_bleu{
  background: #1A4680;
  color: #ffffff;
}

/*                                  */
/* responsive design medias queries */
/*                                  */

/*                         */
/* Ecran de moins de 1050px */
/*                         */

@media screen and (max-width: 1050px) {
  .intro {
    font-size: 1.75rem;
  }

   .entete {
     font-size: 1.5rem;
  }

}

/*                         */
/* Ecran de moins de 900px */
/*                         */
@media screen and (max-width: 900px) {

  .banniere {
  height:150px;
	background-image: url(./images/banniere_petite.JPG);
	}

  .intro {
    font-size: 1.5rem;
  }

   .entete {
     font-size: 1.25rem;
  }

}

/*                         */
/* Ecran de moins de 720px */
/*                         */
@media screen and (max-width: 720px) {



 .article  {
   width:30rem;
 }

 .groupe_bouton {
   width:30rem;
 }

 .intro {
   flex-direction: column;
   justify-content: space-around;
   align-items:center;
   padding: 0.25rem;
 }

 .entete {
   padding: 0.25rem;
 }

}

/*                         */
/* Ecran de moins de 550px */
/*                         */
@media screen and (max-width: 550px) {
  .banniere {
	height:100px;
	background-image: url(./images/banniere_tres_petite.JPG);
	}


  .article  {
    width:25rem;
  }

  .groupe_bouton {
    width:25rem;
  }
 }


/*                          */
/* Ecran de moins de 600px de haut*/
/*                         */
@media screen and (max-height: 600px) {
	.banniere {
	height:150px;
	background-image: url(./images/banniere_petite.JPG);
	}

  .intro {
    font-size: 1.5rem;
  }

  .entete {
     font-size: 1.25rem;
  }
}

@media screen and (max-height: 470px) {
	.banniere {
	height:100px;
	background-image: url(./images/banniere_tres_petite.JPG);
	}


  .poeme{
    font-size: 1rem;
    }

    .theme  {
      font-size:0.4rem;
    }

  .article  {
    width:25rem;
  }

  .groupe_bouton  {
    width:25rem;
  }

  .intro {
    padding: 0.25rem;
    font-size: 1.5rem;
  }

   .entete {
     padding: 0.25rem;
     font-size: 1.25rem;
  }
}

@media screen and (max-height: 400px) {


  .article  {
    margin-top: 0.4rem;
  }

  .titre {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .ligne  {
    margin: 0.2rem;
  }
}

/*                         */
/* Ecran de moins de 430px */
/*                         */

@media screen and (max-width: 430px) {

  .intro {
    font-size: 1.25rem;
  }

   .entete {
     font-size: 1rem;
  }

  .article  {
    width:20rem;
  }

  .poeme {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .ligne  {
    margin: 0.2rem;
  }

  .groupe_bouton {
    width:20rem;
  }

  .bouton {
    margin: .1rem;
    padding: .2rem;
    font-size: 0.75rem;
  }
 }
